import { useContext, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/store/actions/content'

const usePage = (slug, sourcesToPrecache = []) => {
  const { setPageAnimationReady } = useContext(GeneralContext)
  const [isDataFetched, setDataFetched] = useState(false)

  const [pageReady, setPageReady] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const [pageFetched, setPageFetched] = useState({})

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, isPageAnimationReady } = useSelector((state) => {
    // console.log('state --> ', state)
    return ({
      page: state.content.pages[slug] || {},
      isPageAnimationReady: state.isPageAnimationReady,
    })
  }, shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback(() => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------

  /*------------------------------
  Did Mount (Fetch Data)
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchPage(slug)
  }, [])

  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  useEffect(() => {
    setPageReady(isPageAnimationReady)
  }, [isPageAnimationReady])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: sourcesToPrecache,
    callback: () => setPageAnimationReady(true),
  })

  useEffect(() => {
    setIsLoad(load)
  }, [load])

  useEffect(() => {
    setPageFetched(page)
  }, [load])

  return [pageFetched, isLoad, pageReady]
}

export default usePage
