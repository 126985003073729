import { memo } from 'react'
import styled from 'styled-components'
import { a } from 'react-spring'

const Aspan = styled(a.span)`
    display: ${({ children }) => (children === ' ' ? 'inline' : 'inline-block')};
`

const Word = ({ children, astyle }) => {
  return <Aspan className="word" style={astyle}>{children}</Aspan>
}
export default memo(Word)
