const style = (theme) => ({
  Header: {
    paddingTop: theme.spaces[3],
    height: theme.header.height,
    position: 'fixed',
    width: '100%',
    zIndex: theme.zindex.header,
    pointerEvents: 'none',
    boxSizing: 'content-box',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    height: theme.header.height,
    '& svg': {
      height: '100%',
      width: 'auto',
    },
  },
  email: {
    pointerEvents: 'all',
  },
  [theme.mediaInverse.lg]: {
    Header: {
      position: 'absolute',
      height: theme.header.mobile.height,
    },
    logo: {
      height: theme.header.mobile.height,
    },
  },
})

export default style
