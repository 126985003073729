import { RECEIVE_LOCALES, SET_LANGUAGE } from '@/store/actions/types'

const initialState = {
  currentLanguage: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOCALES:
      state = {
        ...state,
        languages: action.payload,
      }
      break
    case SET_LANGUAGE:
      state = {
        ...state,
        currentLanguage: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
