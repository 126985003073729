const style = (theme) => ({
  root: {
    position: 'relative',
    zIndex: 1,
    padding: `calc(${theme.spaces[4]}*1.5) 0px ${theme.spaces[4]} 0px`,
    // background: 'rgb(90,130,145)',
    // background: '-moz-linear-gradient(0deg, rgba(90,130,145,1) 58%, rgba(39,46,49,1) 95%, rgba(39,46,49,0.0) 100%)',
    // background: '-webkit-linear-gradient(0deg, rgba(90,130,145,1) 58%, rgba(39,46,49,1) 95%, rgba(39,46,49,0.0) 100%)',
    background: 'linear-gradient(0deg, rgba(90,130,145,1) 58%, rgba(39,46,49,1) 95%, rgba(39,46,49,0.0) 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#5a8291",endColorstr="#272e31",GradientType=1)',
  },
  container: {
    display: 'flex',
    alignItems: 'start',
    '& .left, & .right': {
      flexBasis: '50%',
    },
    '& .right': {
      display: 'flex',
      justifyContent: 'end',
      flexWrap: 'nowrap',
      flexDirection: 'column',
    },
  },
  address: {
    paddingTop: theme.spaces[0],
    '& p': {
      marginBottom: theme.spaces[1],
    },
  },
  contacts: {
    marginTop: theme.spaces[2],
    '& > div': {
      display: 'flex',
      marginTop: theme.spaces[2],
    },
    '&  a': {
      padding: '15px 30px',
      fontSize: theme.fontSizes[1],
      marginRight: theme.spaces[1],
    },
  },
  bottom: {
    marginTop: theme.spaces[4],
    display: 'flex',
    justifyContent: 'start',
    '& .copy': {
      flexBasis: '50%',
      padding: `10px 0px 0px ${theme.spaces[3]}`,
      '& p': {
        marginBottom: theme.spaces[1],
        fontWeight: 300,
      },
      '& a': {
        color: theme.getRgba(theme.colors[1], 0.6),
        fontWeight: 300,
        display: 'block',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  logo: {
    height: '55px',
    '& svg': {
      height: '100%',
      width: 'auto',
      fill: theme.colors[1],
    },
  },
  thePowerOfLow: {
    '& h3': {
      fontWeight: 600,
      fontSize: '26px',
    },
    '& ul': {
      marginTop: theme.spaces[2],
      '& li': {
        position: 'relative',
      },
      '& li:not(:last-child)': {
        marginBottom: theme.spaces[1],
      },
    },
    '&  a': {
      padding: '15px 30px',
      fontSize: theme.fontSizes[1],
      marginTop: theme.spaces[2],
      backgroundColor: theme.colors[0],
      width: 'fit-content',
    },

  },
  p0: {},
  [theme.mediaInverse.lg]: {
    p0: { padding: '0px' },
    container: {
      display: 'flex',
      alignItems: 'start',
      flexDirection: 'column',
      '& .left, & .right': {
        flexBasis: '100%',
      },
    },
    contacts: {
      '& > div': {
        flexDirection: 'column',
      },
      '&  a': {
        marginRight: '0px',
        marginBottom: theme.spaces[1],
        width: 'fit-content',
      },
    },
    thePowerOfLow: {
      marginTop: theme.spaces[4],

    },
    bottom: {
      marginTop: theme.spaces[3],
      flexDirection: 'column',
      '& .copy': {
        marginTop: theme.spaces[3],
        padding: '10px 0px 0px 0px',
      },
    },
  },
})

export default style
