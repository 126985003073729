import { memo } from 'react'

const SVGSprite = () => {
  return (
    <svg style={{ display: 'none' }}>
      <symbol id="ico-close" viewBox="0 0 20 20">
        <path d="M20 1.42L18.58 0 10 8.58 1.42 0 0 1.42 8.58 10 0 18.58 1.42 20 10 11.42 18.58 20 20 18.58 11.42 10z" />
      </symbol>
      <symbol id="ico-close-lightbox" viewBox="0 0 8 8">
        <path d="M8 .806 7.194 0 4 3.194.806 0 0 .806 3.194 4 0 7.194.806 8 4 4.806 7.194 8 8 7.194 4.806 4Z" />
      </symbol>
      <symbol id="ico-arrow-left" viewBox="0 0 5 10">
        <path d="M5 0 0 5l5 5V0z" />
      </symbol>
      <symbol id="ico-arrow-right" viewBox="0 0 5 10">
        <path d="m0 10 5-5-5-5v10z" />
      </symbol>
      <symbol id="ico-arrow-right-triangular" viewBox="0 0 24 24">
        <path d="M21 12L3 24V0z" />
      </symbol>
      <symbol id="ico-play" viewBox="0 0 31.5 36.37">
        <path d="M30.5 18.19l-15 8.66-15 8.66V.87l15 8.66 15 8.66z" />
      </symbol>
      <symbol id="ico-fullscreen" viewBox="0 0 17 17">
        <path d="M0 7V0h7v2H2v5zm10-7h7v7h-2V2h-5zm7 10v7h-7v-2h5v-5zM7 17H0v-7h2v5h5z" />
      </symbol>
      <symbol id="ico-arrow" viewBox="0 0 53.75 26.5">
        <path d="M52.25 12.25H5.49l9.69-9.69A1.5 1.5 0 1013.06.44L0 13.5l12.56 12.56a1.5 1.5 0 002.12 0 1.49 1.49 0 000-2.12L6 15.25h46.25a1.5 1.5 0 000-3z" />
      </symbol>
      <symbol id="ico-facebook" viewBox="0 0 8.65 16">
        <g transform="translate(-22.077)">
          <path id="Tracciato_11" data-name="Tracciato 11" d="M30.4,0,28.327,0a3.644,3.644,0,0,0-3.837,3.938V5.753H22.4a.326.326,0,0,0-.326.326V8.71a.326.326,0,0,0,.326.326h2.086v6.637a.326.326,0,0,0,.326.326h2.722a.326.326,0,0,0,.326-.326V9.036H30.3a.326.326,0,0,0,.326-.326V6.08a.326.326,0,0,0-.326-.326h-2.44V4.214c0-.74.176-1.115,1.14-1.115h1.4a.326.326,0,0,0,.326-.326V.33A.326.326,0,0,0,30.4,0Z" />
        </g>
      </symbol>
      <symbol id="ico-facebook2" viewBox="0 0 512 512">
        <path d="M288 176v-64c0-17.664 14.336-32 32-32h32V0h-64c-53.024 0-96 42.976-96 96v80h-64v80h64v256h96V256h64l32-80h-96z" />
      </symbol>
      <symbol id="ico-instagram" viewBox="0 0 16 16">
        <path d="M11.584,0H4.415A4.42,4.42,0,0,0,0,4.415v7.169A4.42,4.42,0,0,0,4.415,16h7.169A4.42,4.42,0,0,0,16,11.585V4.415A4.42,4.42,0,0,0,11.584,0Zm3,11.585a3,3,0,0,1-3,3H4.415a3,3,0,0,1-3-3V4.415a3,3,0,0,1,3-3h7.169a3,3,0,0,1,3,3v7.169Z" transform="translate(0)" />
        <path d="M45.379,40.97a4.411,4.411,0,1,0,4.411,4.411A4.416,4.416,0,0,0,45.379,40.97Zm0,7.3a2.892,2.892,0,1,1,2.892-2.892A2.9,2.9,0,0,1,45.379,48.274Z" transform="translate(-37.379 -37.381)" />
        <path d="M120.422,28.251a1.5,1.5,0,1,0,1.061.439A1.506,1.506,0,0,0,120.422,28.251Z" transform="translate(-108.409 -25.754)" />
      </symbol>
      <symbol id="ico-instagram2" viewBox="0 0 64 64">
        <path d="M18.5.3h27.1C55.7.3 64 8.5 64 18.5v27c0 10-8.3 18.2-18.5 18.2h-27C8.3 63.7 0 55.5 0 45.5v-27C0 8.5 8.3.3 18.5.3zm13.3 14.6c9.9 0 17.9 8 17.9 17.9 0 9.9-8 17.9-17.9 17.9-9.9 0-17.9-8-17.9-17.9 0-9.9 8.1-17.9 17.9-17.9zm0 6c6.5 0 11.8 5.3 11.8 11.8s-5.3 11.8-11.8 11.8c-6.5 0-11.8-5.3-11.8-11.8 0-6.5 5.3-11.8 11.8-11.8zm17.3-8.5c1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9zM20.7 5.5h22.6c8.5 0 15.5 6.9 15.5 15.4v22.8c0 8.5-7 15.4-15.5 15.4H20.7C12.2 59 5.2 52 5.2 43.6V20.8c0-8.4 7-15.3 15.5-15.3z" fillRule="evenodd" clipRule="evenodd" />
      </symbol>
      <symbol id="ico-linkedin" viewBox="0 0 50 50">
        <path d="M26.5,23.16v-.05a.1.1,0,0,1,0,.05Z" />
        <path d="M25,0A25,25,0,1,0,50,25,25,25,0,0,0,25,0ZM18.18,37H12.82V20.83h5.36ZM15.5,18.62h0a2.77,2.77,0,0,1-3-2.78,2.8,2.8,0,0,1,3-2.79,2.79,2.79,0,1,1,0,5.57ZM37.5,37H32.14V28.32c0-2.16-.77-3.64-2.71-3.64a2.93,2.93,0,0,0-2.75,2,3.44,3.44,0,0,0-.18,1.3v9H21.14s.07-14.61,0-16.12H26.5v2.28a5.32,5.32,0,0,1,4.83-2.66c3.52,0,6.17,2.3,6.17,7.25Z" />
      </symbol>
      <symbol id="ico-linkedin1" viewBox="0 0 448 512">
        <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
      </symbol>
      <symbol id="ico-pinterest" viewBox="0 0 50 50">
        <path d="M25 0a25 25 0 1025 25A25 25 0 0025 0zm2.58 30.57a4.13 4.13 0 01-3.51-1.79s-.83 3.31-1 3.94a15.23 15.23 0 01-2.59 4.7.19.19 0 01-.34-.08 16 16 0 010-5.46c.28-1.16 1.85-7.82 1.85-7.82a5.52 5.52 0 01-.46-2.27c0-2.13 1.24-3.72 2.77-3.72a1.92 1.92 0 011.94 2.16c0 1.31-.84 3.27-1.27 5.09a2.22 2.22 0 002.27 2.77c2.72 0 4.55-3.5 4.55-7.64 0-3.14-2.12-5.5-6-5.5a6.81 6.81 0 00-7.07 6.88 4.11 4.11 0 00.95 2.81.72.72 0 01.21.81c-.07.26-.23.9-.29 1.15a.5.5 0 01-.72.36c-2-.82-2.94-3-2.94-5.49 0-4.08 3.44-9 10.26-9 5.48 0 9.09 4 9.09 8.23.05 5.66-3.08 9.87-7.7 9.87z" />
      </symbol>
      <symbol id="ico-twitter" viewBox="0 0 50 50">
        <path d="M25 0a25 25 0 1025 25A25 25 0 0025 0zm9.94 19.9v.67A14.6 14.6 0 0112.5 32.86c.4 0 .81.07 1.22.07a10.24 10.24 0 006.37-2.2 5.13 5.13 0 01-4.79-3.56 5.23 5.23 0 001 .09 5 5 0 001.35-.18 5.13 5.13 0 01-4.12-5V22a5.26 5.26 0 002.33.64 5.15 5.15 0 01-1.59-6.85 14.56 14.56 0 0010.57 5.36 5.67 5.67 0 01-.16-1.15 5.13 5.13 0 018.87-3.51 10.2 10.2 0 003.26-1.24 5.12 5.12 0 01-2.25 2.83 10.4 10.4 0 002.94-.8 10.41 10.41 0 01-2.56 2.62z" />
      </symbol>
      <symbol id="ico-email" viewBox="0 0 30 30">
        <path d="M3.08 5.45a3.14 3.14 0 00-2.27.81A3.15 3.15 0 000 8.52v13a3.12 3.12 0 00.82 2.26 3.18 3.18 0 002.28.81h23.84a3.14 3.14 0 002.26-.82 3.17 3.17 0 00.8-2.28v-13a3.1 3.1 0 00-.81-2.24 3.1 3.1 0 00-2.19-.8zm.33 2.05h23.18l-11.08 9a1 1 0 01-1 0zM2.05 9l7.34 6-7.34 6.22zM28 9v12.2L20.61 15zm-17 7.28c.85.64 1.67 1.42 2.53 2a3.35 3.35 0 003 0c.86-.61 1.69-1.34 2.53-2l7.3 6.22H3.69z" />
      </symbol>
      <symbol id="ico-telephone" viewBox="0 0 18.6 18.6">
        <path d="M19.774,15.457v2.5a1.67,1.67,0,0,1-1.82,1.67,16.524,16.524,0,0,1-7.206-2.563,16.282,16.282,0,0,1-5.01-5.01A16.524,16.524,0,0,1,3.175,4.82,1.67,1.67,0,0,1,4.836,3h2.5a1.67,1.67,0,0,1,1.67,1.436A10.721,10.721,0,0,0,9.6,6.782,1.67,1.67,0,0,1,9.22,8.544L8.159,9.6a13.359,13.359,0,0,0,5.01,5.01l1.06-1.06a1.67,1.67,0,0,1,1.762-.376,10.721,10.721,0,0,0,2.346.584,1.67,1.67,0,0,1,1.436,1.695Z" transform="translate(-2.167 -2)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </symbol>
      <symbol id="ico-pencil" viewBox="0 0 17.7 17.7">
        <path d="M17.526,1.928a2.77,2.77,0,0,1,2.216,4.431L18.634,7.467,14.757,3.59l1.108-1.108a2.757,2.757,0,0,1,1.662-.554ZM3.679,14.668,2.571,19.653l4.985-1.108L17.8,8.3,13.926,4.421ZM14.957,8.221,7.2,15.976l-.955-.955L14,7.267l.955.955Z" transform="translate(-2.571 -1.928)" stroke="none" />
      </symbol>
      <symbol id="ico-refresh" viewBox="0 0 18 18">
        <path d="M21.37,8.645a9.007,9.007,0,1,0,2.341,8.612H21.37a6.751,6.751,0,1,1-6.36-9.006,6.658,6.658,0,0,1,4.75,2L16.135,13.88h7.88V6Z" transform="translate(-6.015 -6)" fill="#fff" />
      </symbol>
      <symbol id="ico-goodreads" viewBox="0 0 24 24">
        <path d="M17.1,2.7h-1.5v2.2c0,0-0.1,0-0.1-0.1c-0.5-1.1-1.9-2.5-4.1-2.5C8.6,2.4,6.7,4,6,6.5C5.8,7.3,5.7,8.1,5.7,9
    c0.1,4.2,2.4,6.3,6,6.2c1.5-0.1,2.9-0.9,3.7-2.4c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0,0.2,0,1.6,0,1.8c0,0.8-0.1,1.6-0.4,2.3
    c-0.4,1.1-1.2,1.9-2.4,2.1c-0.9,0.2-1.9,0.2-2.8-0.1c-1.2-0.3-2-1-2.2-2.2c0-0.1-0.1-0.1-0.1-0.1H6.2c0,0.6,0.2,1.1,0.5,1.6
    c1.3,2.2,4.4,2.6,6.9,2c2.7-0.7,3.6-2.9,3.6-5.7v0C17.1,14.3,17.1,12.5,17.1,2.7z M13.6,13.2c-1.2,0.8-2.8,0.8-3.8,0.3
    c-2-0.9-2.6-3.1-2.5-5.2c0.2-3.3,2.2-4.7,4-4.7c2.5,0,3.8,1.7,4.2,4.2C15.8,9.8,15.3,12,13.6,13.2z"
        />
      </symbol>
    </svg>
  )
}

export default memo(SVGSprite)
