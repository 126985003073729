const style = () => ({
  ScrollDown: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  Dots: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    '& span': {
      display: 'inline-block',
      height: '3px',
      width: '3px',
      borderRadius: '1px',
      marginBottom: '2px',
    },
  },
})

export default style
