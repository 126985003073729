const style = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spaces[4]} auto`,
  },
  quoteWrapper: {
    width: '60%',
    margin: `${theme.spaces[4]} auto`,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  videoWrapper: {
    width: '55%',
    position: 'relative',
    marginBottom: theme.spaces[3],
    '& figure': {
      position: 'relative',
      paddingTop: '100%',
      top: '0px',
      height: '100%',
      width: '100%',
      borderRadius: '50%',
      overflow: 'hidden',
      margin: 0,
      '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
      '& .videoPlayer': {
        position: 'absolute',
        top: '0px',
        height: '100%',
        width: '100%',
      },

    },
  },
  citWrapper: {
    position: 'relative',
    width: '100%',
    padding: '0px',
    boxSizing: 'content-box',
    height: '300px',
    '& > div': {
      width: '100%',
      position: 'absolute',
    },
    '& p ': {
      width: '100%',
      fontWeight: 300,
      fontStyle: 'italic',
      color: theme.getRgba(theme.colors[1], 0.6),
      textAlign: 'center',
    },
    '& .author': {
      marginTop: theme.spaces[3],
    },
  },

  refreshWrapper: {
    position: 'absolute',
    bottom: '0px',
    right: '30px',
    zIndex: 2,
    transition: 'transform ease-out 0.3s',
    '&:hover': {
      transform: 'rotate(45deg)',
    },
  },
  refresh: {
    cursor: 'pointer',
    backgroundColor: theme.colors[0],
    borderRadius: '50%',
    width: '92px',
    height: '92px',
    padding: '36px',
    boxShadow: '0px 0px 10px #000',
    position: 'relative',
    overflow: 'hidden',
    '& svg': {
      width: '100%',
      height: '100%',
    },
    '&:hover': {
      backgroundColor: '#121312',

    },
    '& svg.progress': {
      position: 'absolute',
      top: '0px',
      left: '0px',
      fill: 'none',
      stroke: theme.colors[3],
      strokeWidth: '5px',
      strokeDasharray: '314',
      strokeLinecap: 'round',
    },
  },
  [theme.mediaInverse.md]: {
    quoteWrapper: {
      width: '100%',
      padding: `0px ${theme.spaces[1]}`,
      margin: `${theme.spaces[2]} auto`,
    },
    videoWrapper: {
      width: '100%',
      marginBottom: theme.spaces[2],
    },
    citWrapper: {
      height: '250px',
    },
  },
})

export default style
