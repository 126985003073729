/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useRef, useCallback } from 'react'
import injectSheet from 'react-jss'
import { useSpring, a } from 'react-spring'
import style from './style'
import { Container } from '@/components/_commons/grid'
import VideoPlayer from '@/components/__ui/media/VideoPlayer'
import AttractMe from '@/components/__ui/wrappers/magnetic/AttractMe'

import InViewObserver from '@/components/__ui/reveals/InViewObserver'
import RevealFadeUp from '@/components/__ui/reveals/RevealFadeUp'

import Cit from './Cit'

const VideoQuotes = ({ item, classes }) => {
  // console.log('item', item)
  const [quoteIndex, setQuoteIndex] = useState(0)
  const timeout = useRef()
  const time = 8000

  const [rstyle, rApi] = useSpring(() => ({
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
    config: {
      mass: 4,
      friction: 70,
    },
    onRest: () => {
      rApi.set({
        transform: 'rotate(0deg)',
      })
    },
  }))

  const [pstyle, pApi] = useSpring(() => ({
    from: {
      strokeDashoffset: 314,
    },
    to: {
      strokeDashoffset: 0,
    },
    config: {
      mass: 4,
      friction: 70,
      duration: time,
    },
  }))

  const setRandomQuote = useCallback(() => {
    clearTimeout(timeout.current)
    while (true) {
      const qi = Math.floor(Math.random() * item.quotes.length)
      if (qi !== quoteIndex) {
        setQuoteIndex(qi)
        break
      }
    }
  }, [item, setQuoteIndex, quoteIndex])

  useEffect(() => {
    rApi.start({
      transform: 'rotate(360deg)',
    })
    pApi.set({
      strokeDashoffset: 314,
    })
    pApi.start({
      strokeDashoffset: 0,
    })

    timeout.current = setTimeout(setRandomQuote, time)
    // console.log('quoteIndex trigger', timeout.current)
  }, [quoteIndex])

  return (
    <Container className={classes.root}>
      <div className={classes.quoteWrapper}>

        <InViewObserver>
          <RevealFadeUp>
            <div className={classes.videoWrapper}>
              <div className={classes.refreshWrapper}>
                <AttractMe friction={0.87} attractionRadius={400}>
                  <a.div
                    className={classes.refresh}
                    onClick={() => setRandomQuote()}
                  >
                    <a.svg viewBox="0 0 100 100" className="progress" style={pstyle}>
                      <circle cx="50" cy="50" r="47.5" />
                    </a.svg>
                    <a.svg style={rstyle}>
                      <use xlinkHref="#ico-refresh" />
                    </a.svg>
                  </a.div>
                </AttractMe>
              </div>

              <figure>
                <VideoPlayer
            // video="https://gustosito.s3.eu-west-3.amazonaws.com/henge/H20-2021.mp4"
                  video={item.video_url}
                  loop={true}
                  autoplay={true}
                  poster={item.poster.sizes.medium}
                />
              </figure>
            </div>
          </RevealFadeUp>
        </InViewObserver>

        <a.div className={classes.citWrapper}>
          {
            item.quotes.map((q, i) => {
              return (
                <Cit
                  key={q.quote}
                  quote={q}
                  index={i}
                  current={quoteIndex}
                />
              )
            })
          }
        </a.div>

      </div>
    </Container>
  )
}

export default injectSheet(style)(VideoQuotes)
