const style = () => ({
  root: {
    borderRadius: (props) => props.round,
    overflow: 'hidden',
    '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
    background: (props) => props.background,
  },

})

export default style
