const style = () => ({
  root: {
    overflow: 'hidden',
    height: '100%',
    '& > div': {
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      transition: 'transform ease-in-out 0.7s',
      display: 'block',
      '& img': {
        opacity: 0,
        width: '100%',
        height: 'auto',
      },
    },
  },
})

export default style
