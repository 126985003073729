import { createUseStyles } from 'react-jss'
import useResponsive from '@/hooks/useResponsive'

const s = (theme) => ({
  t: ({ lg }) => {
    return ({
      fontSize: lg ? theme.fontSizes.mobile[6] : theme.fontSizes[6],
      margin: '0px',
      '& *': {
        margin: 0,
      },
    })
  },
})
const useStyle = createUseStyles(s)

const T6 = ({ children, asTag, className, style, dangerouslySetInnerHTML }) => {
  const [,, lg] = useResponsive('lg')
  const classes = useStyle({ lg })
  const Tag = asTag

  return (
    <Tag style={style} className={`${className} ${classes.t}`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Tag>
  )
}

T6.defaultProps = {
  asTag: 'h1',
  className: '',
}

export default T6
