/* eslint-disable consistent-return */
import { useRef, useEffect, useState, useMemo, Children, cloneElement } from 'react'

const InViewObserver = (props) => {
  const { once, children, className, style, enterCallback, leaveCallback, options } = props
  const $root = useRef(null)
  const [inview, setInview] = useState(false)

  const observer = useMemo(() => new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      // console.log('entry', entry)
      if (!entry.isIntersecting) {
        setInview(false)
        if (leaveCallback) leaveCallback()
        return
      }
      setInview(true)
      if (once) observer.unobserve($root.current)
      if (enterCallback) enterCallback()
    })
  }, options), [options])

  useEffect(() => {
    if (!$root.current) return
    observer.observe($root.current)
  }, [])

  return (
    <div ref={$root} className={`${className} in-view-obs`} style={{ ...style, width: '100%' }}>
      {Children.map(children, (child) => {
        return cloneElement(child, { inview: typeof child.type === 'string' ? undefined : inview })
      })}
    </div>
  )
}

InViewObserver.defaultProps = {
  once: true,
  enterCallback: null,
  leaveCallback: null,
  options: {
    threshold: 0.5,
  },
  className: '',
}

export default InViewObserver
