import { theme } from '../../../styles/style'

const style = () => ({
  SquareButton: {
    display: 'flex',
    height: 'fit-content',
    padding: '7px 20px',
    color: theme.colors[1],
    backgroundColor: 'transparent',
    border: `solid 1px ${theme.colors[1]}`,
    borderRadius: '4px',
    fontSize: '14px',
    transition: 'all ease-out 0.3s',
    '& svg': {
      height: '14px',
      width: '14px',
      marginLeft: '15px',
      '& *': {
        fill: theme.colors[1],
        stroke: theme.colors[1],
      },
    },
    '&:hover': {
      color: theme.colors[3],
      borderColor: theme.colors[3],
    },
  },
})

export default style
