const style = (theme) => ({
  progressBarWrapper: {
    position: 'fixed',
    zIndex: 0,
    top: `calc(${theme.spaces[2]} + ${theme.header.height} + ${theme.spaces[4]} + 10px)`,
    width: '100%',
    height: '47vh',
    pointerEvents: 'none',
  },
  track: {
    width: '1px',
    height: '100%',
    backgroundColor: theme.getRgba(theme.colors[1], 0.2),
  },
  thumb: {
    width: '100%',
    height: '60px',
    backgroundColor: theme.colors[1],
  },

})

export default style
