import { createContext, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import usePrevious from '@/hooks/usePrevious'

const Context = createContext({})
const { Provider, Consumer } = Context

const LoadingContext = ({
  children,
}) => {
  const [ready, setReady] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [siteLoaded, setSiteLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isFontReady, setFontReady] = useState(false)
  const [isLoaderExited, setLoaderExited] = useState(false)

  const pathname = useSelector((state) => state.router.location.pathname)
  const prevPathname = usePrevious(pathname)

  useEffect(() => {
    if (prevPathname !== undefined && prevPathname !== pathname) {
      setReady(false)
      setFirstLoad(false)
      setIsLoading(true)
    }
  }, [pathname])

  useEffect(() => {
    if (ready) setIsLoading(false)
  }, [ready])

  return (
    <Provider value={{
      ready,
      setReady,
      firstLoad,
      setFirstLoad,
      siteLoaded,
      setSiteLoaded,
      isLoading,
      setIsLoading,
      isFontReady,
      setFontReady,
      isLoaderExited,
      setLoaderExited,
    }}
    >
      { children }
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default LoadingContext
