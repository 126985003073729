import RevealFadeIn from '@/components/__ui/reveals/RevealFadeIn'
import RevealText from '@/components/__ui/reveals/RevealText'
import { T3, T1 } from '@/components/_commons/Text'
import withInViewTrigger from '@/hoc/withInViewTrigger'

const Cit = ({ quote, index, current, inview }) => {
  return (
    <>
      <div>
        <RevealFadeIn
          inview={index === current && inview}
          enterDuration={0.6}
          exitDuration={0.3}
          style={{ height: '100%' }}
        >
          <T3>
            <RevealText
              inview={index === current && inview}
              from={() => ({
                y: 5,
                scale: 0.5,
                opacity: 0,
              })}
              to={(inView) => ({
                scale: inView ? 1 : 0.5,
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 5,
              })}
              config={{
                mass: 1,
                tension: 3000,
                friction: 120,
                precision: 0.01,
              }}
            >
              {quote.quote}

            </RevealText>
          </T3>
          <T1 className="author">
            {quote.author}
          </T1>
        </RevealFadeIn>
      </div>
    </>
  )
}

export default withInViewTrigger(Cit)
