import { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import useResponsive from '@/hooks/useResponsive'

const s = (theme) => ({
  t: ({ lg }) => ({
    fontSize: lg ? theme.fontSizes.mobile[3] : theme.fontSizes[3],
    fontWeight: 300,
    margin: '0px',
    '& *': {
      margin: 0,
    },
  }),
})
const useStyle = createUseStyles(s)

const T3 = forwardRef(({ children, asTag, className, style, dangerouslySetInnerHTML }, ref) => {
  const [,, lg] = useResponsive('lg')
  const classes = useStyle({ lg })
  const Tag = asTag

  return (
    <Tag style={style} className={`${className} ${classes.t}`} dangerouslySetInnerHTML={dangerouslySetInnerHTML} ref={ref}>
      {children}
    </Tag>
  )
})

T3.defaultProps = {
  asTag: 'p',
  className: '',
}

export default T3
