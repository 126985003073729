const style = (theme) => ({
  cubesWrapper: {
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    zIndex: theme.zindex.header,
    pointerEvents: 'none',
  },
})

export default style
