const style = (theme) => ({
  Container: {
    width: '100%',
    maxWidth: '1740px',
    margin: '0px auto',
    padding: `0px ${theme.spaces[4]}`,
    [theme.mediaInverse.xl]: {
      padding: `0px ${theme.spaces[3]}`,
    },
    [theme.mediaInverse.lg]: {
      padding: `0px ${theme.spaces[1]}`,
    },
  },
})

export default style
