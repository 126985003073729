import injectSheet from 'react-jss'
import ReactHtmlParser from 'react-html-parser'
import { Canvas } from '@react-three/fiber'
import style from './style'
import { Container } from '@/components/_commons/grid'
import { T4, T1 } from '@/components/_commons/Text'
import ScrollDown from './ScrollDown'

// import RevealText from '@/components/__ui/reveals/RevealText'
import Scene from './Scene'

const Hero = ({ item, classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.canvasWrapper}>
        <Canvas>
          <Scene />
        </Canvas>
      </div>
      <Container>
        <Container className={classes.heroWrapper}>
          <T4 asTag="p">
            {ReactHtmlParser(item.title)}
          </T4>
          <div className={classes.colWrapper}>
            <T1 className="t">
              {ReactHtmlParser(item.text)}
            </T1>
          </div>
          <div className={classes.colWrapper}>
            <div className="s">
              <ScrollDown />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
}

export default injectSheet(style)(Hero)
