import { useRef, useMemo } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { LinearEncoding } from 'three'

const Scene = () => {
  const three = useThree()
  const { size: { width, height }, gl, clock } = three
  gl.outputEncoding = LinearEncoding
  // gl.toneMapping = THREE.NoToneMapping
  const $plane = useRef(null)

  // const loader = new THREE.TextureLoader()
  // loader.setCrossOrigin('anonymous')

  const shaderArgs = useMemo(() => (
    {
      vertexShader: /* glsl */`
            varying vec2 vUv;
            varying vec3 vPosition;
            void main() {
                vUv = uv;
                vPosition = position;
                gl_Position =  projectionMatrix * modelMatrix * viewMatrix * vec4(position * 1.0, 1.0) ;
            }
        `,
      fragmentShader: /* glsl */`
      #define PI 3.141592653589
      #define PI2 6.28318530718
  
      uniform vec2 uMouse;
      uniform vec2 uResolution;
      uniform float uTime;
      uniform float uA;
      uniform float uB;
      uniform float uL;
      uniform float uZoom;
     
      varying vec2 vUv;
      varying vec3 vPosition;
  
  
      void main(){
          float aspect = (uResolution.x/uResolution.y) ;
         //vec2 uv = (vUv - vec2(0.5)) * vec2((uResolution.x/uResolution.y), 1.0);
  
          vec2 uv = gl_FragCoord.xy / uResolution.xy;
  
          float w = uResolution.x;
          float h = uResolution.y;
          
          float aspectRatio = h / w;
          
          uv.y *= aspectRatio;
          uv.y -= (aspectRatio - 1.) * .5;
          
          vec2 center = uv - 0.5;
          center /= uZoom ;
          
          float wA = uA;
          float wB = uB;
          
          vec3 orange = vec3(0.89, 0.63, 0.3); // rgb(229, 161, 78)
          vec3 teal = vec3(0.35, 0.50, 0.56);  // rgba(90, 130, 145)
  
          float wA0 = sin(wA / (abs(center.x) + uL)   + uTime * wA) ;
          float wA1 = sin(wA / (abs(center.y) + uL)   + uTime * wA) ;
  
          float wB0 = sin(wB / (abs(center.x) + uL)   + uTime * wB) ;
          float wB1 = sin(wB / (abs(center.y) + uL)   + uTime * wB) ;
          
          vec3 c1 = teal * wA0 * wA1;
          vec3 c2 = teal * wB0 * wB1;
          
          // Output to screen
         // vec3 color = vec3(c.xyz * clamp(c.a, 0., 1.));
          vec3 color =  vec3((c1 + c2) );
  
          gl_FragColor = vec4( color * 0.22, (c1 + c2)  ).rgba;
      }
        `,
      uniforms: {
        uTime: { value: 0 },
        uMouse: { value: [0, 0] },
        uResolution: { value: [width, height] },
        uZoom: { value: 0.73 },
        uB: { value: 3.37 },
        uA: { value: 0.51 },
        uL: { value: 0.04 },
      },
    }), [width, height])

  useFrame(() => {
    if (!$plane?.current) return
    $plane.current.material.uniforms.uTime.value = clock.getElapsedTime()
  })

  return (
    <mesh ref={$plane}>
      <planeBufferGeometry args={[width, height]} />
      <shaderMaterial args={[shaderArgs]} />
    </mesh>
  )
}

export default Scene
