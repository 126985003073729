const style = (theme) => ({
  root: {
    // marginTop: `calc(${theme.spaces[3]} + ${theme.header.height})`,
    paddingTop: `calc(${theme.spaces[2]} + ${theme.header.height} + ${theme.spaces[4]})`,
    paddingBottom: theme.spaces[4],
    position: 'relative',
    background: 'linear-gradient(0deg, rgba(39,46,49,1) 0%, rgba(39,46,49,0) 100%)',
    minHeight: 'var(--vh-fixed)',

  },
  canvasWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
  },
  heroWrapper: {
    padding: `0px calc(${theme.spaces[4]} + ${theme.spaces[3]} + ${theme.spaces[2]})`,
  },
  colWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    // padding: `0px ${theme.spaces[4]}`,
    '& .s': {
      marginTop: theme.spaces[3],
      width: '63%',
    },
    '& p.t': {
      marginTop: theme.spaces[3],
      maxWidth: '63%',
      color: theme.getRgba(theme.colors[1], 1),
    },
  },
  [theme.mediaInverse.xl]: {
    heroWrapper: {
      padding: `0px calc(${theme.spaces[4]} + ${theme.spaces[3]}) `,
    },
    colWrapper: {
      '& .s': {
        width: '70%',
      },
      '& p.t': {
        maxWidth: '70%',
      },
    },
  },
  [theme.mediaInverse.lg]: {
    root: {
      marginTop: 0,
      minHeight: 'unset',
    },
  },
  [theme.mediaInverse.md]: {
    colWrapper: {
      padding: `0px  ${theme.spaces[1]}`,
      '& .s': {
        width: '100%',
      },
      '& p.t': {
        marginTop: theme.spaces[3],
        maxWidth: '100%',
      },
    },
  },
  [theme.mediaInverse.sm]: {
    heroWrapper: {
      padding: '0px',
    },
    textWrapper: {
      marginTop: theme.spaces[3],
      padding: `0px ${theme.spaces[2]}`,
    },
  },
})

export default style
