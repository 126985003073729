/* eslint-disable jsx-control-statements/jsx-use-if-tag */
/* eslint-disable no-plusplus */
/* eslint-disable react/display-name */
/* eslint-disable react/no-array-index-key */
import { useState, useRef, Children, useEffect, useCallback, useLayoutEffect, forwardRef, useMemo } from 'react'
import styled from 'styled-components'
import Line from './Line'
import Word from './Word'
import Char from './Char'

const RootSpan = styled.span`
    display: inline-block;
`

const AnimatedTextSplitter = forwardRef((props, ref) => {
  const { children, className, animatedTrail, animateWords } = props
  const [text, setText] = useState('')
  const [lines, setLines] = useState([])
  const $el = useRef(null)

  useEffect(() => {
    Children.map(children, (child) => {
      if (typeof child === 'string' || typeof child === 'number') {
        setText(text + String(child))
      } else {
        throw new Error('AnimatedTextSplitter expect a text as children')
      }
    })
  }, [children])

  const createLines = useCallback(() => {
    const el = $el.current
    if (!el) return
    let prevY
    const newLines = []
    const words = Array.from(el.children)

    for (let i = 0; i < words.length; i++) {
      const w = words[i]
      const y = w.getBoundingClientRect().top
      if (prevY !== y && w.textContent.trim().length) {
        newLines.push([])
        prevY = y
      }
      if (prevY === y) {
        newLines[newLines.length - 1].push(w.textContent.trim().split(''), [' '])
      }
      prevY = y
    }
    setLines(newLines)
  }, [$el])

  useLayoutEffect(() => {
    createLines()
    // console.log('text', text)
  }, [text])

  const animationIndex = useMemo(() => ({
    value: 0,
  }), [animatedTrail])

  // useEffect(() => {
  //   console.log('lines', lines)
  // }, [lines])

  return lines.length
    ? (
      <RootSpan
        className={`text-splitter ${className}`}
        ref={ref}
      >
        {
                lines.map((line, i) => {
                  return (
                    <Line key={line[0][0][0] + i}>
                      {
                                line.map((word, j) => {
                                  const wrd = word.join('')
                                  let wstyle = {}
                                  if (animateWords && wrd !== ' ') {
                                    let index
                                    if (animationIndex.value < animatedTrail.length) index = animationIndex.value++
                                    wstyle = animatedTrail[index]
                                  }
                                  return wrd === ' '
                                    ? <Char key={i + j}>{wrd}</Char>
                                    : (
                                      <Word key={i + j} astyle={wstyle}>
                                        {
                                                word.map((char, k) => {
                                                  let cstyle = {}
                                                  if (!animateWords) {
                                                    let index
                                                    if (animationIndex.value < animatedTrail.length) index = animationIndex.value++
                                                    cstyle = animatedTrail[index]
                                                  }
                                                  return (
                                                    <Char
                                                      key={char + k}
                                                      astyle={cstyle}
                                                    >
                                                      {char}

                                                    </Char>
                                                  )
                                                })
                                            }
                                      </Word>
                                    )
                                })
                            }
                    </Line>
                  )
                })
            }
      </RootSpan>
    )
    : (
      <span
        ref={(el) => { $el.current = el }}
      >
        {text.split(' ').map((word, i) => (
          <span key={i} className="word">
            {word}
            {' '}
          </span>
        ))}
      </span>
    )
})

export default AnimatedTextSplitter
