export const icoPlay = () => (
  <svg viewBox="0 0 36 36">
    <path d="M 12,26 18.5,22 18.5,14 12,10 z M 18.5,22 25,18 25,18 18.5,14 z" />
  </svg>
)

export const icoPause = () => (
  <svg viewBox="0 0 36 36">
    <path d="M 12,26 16,26 16,10 12,10 z M 21,26 25,26 25,10 21,10 z" />
  </svg>
)

export const icoFullscreen = () => (
  <svg viewBox="0 0 36 36">
    <path d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z" />
    <path d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z" />
    <path d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z" />
    <path d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z" />
  </svg>
)

export const icoVolumeOn = () => (
  <svg viewBox="0 0 36 36">
    <path d="M18,10l-5.4,5.4H9.1v5.4h3.6L18,26V10z M19.8,11.8v1.8c2,0.4,3.6,2.3,3.6,4.5s-1.5,4-3.6,4.5v1.8 c3-0.4,5.4-3.1,5.4-6.2S22.8,12.2,19.8,11.8z M19.8,15.3v5.4c1-0.3,1.8-1.4,1.8-2.7S20.8,15.7,19.8,15.3z" />
  </svg>
)

export const icoVolumeOff = () => (
  <svg viewBox="0 0 36 36">
    <path d="M10.4,9.2l-1.3,1.3l4.3,4.2l-0.7,0.7H9.1v5.3h3.6L18,26v-6.8l4.1,4.1c-0.7,0.4-1.4,0.7-2.3,0.8v1.8 c1.3-0.1,2.5-0.6,3.6-1.3l2.3,2.3l1.3-1.3L10.4,9.2z M18,10l-2.2,2.2l2.2,2.1V10z M19.8,10.2V12c3,0.4,5.3,2.9,5.3,6.1 c0,1-0.3,1.9-0.7,2.8l1.3,1.3c0.7-1.2,1.2-2.6,1.2-4.1C26.9,14,23.8,10.6,19.8,10.2z M19.8,14.5v1.6l2.6,2.6 c0.1-0.2,0.1-0.5,0.1-0.7C22.5,16.3,21.3,15,19.8,14.5z" />
  </svg>
)
