import { createUseStyles } from 'react-jss'
import useResponsive from '@/hooks/useResponsive'

const s = (theme) => ({
  t: ({ lg }) => ({
    fontSize: lg ? theme.fontSizes.mobile[0] : theme.fontSizes[0],
    fontWeight: 300,
    '& *': {
      margin: 0,
    },
  }),
})

const useStyle = createUseStyles(s)

const T0 = ({ children, asTag, className, style, dangerouslySetInnerHTML }) => {
  const [,, lg] = useResponsive('lg')
  const classes = useStyle({ lg })
  const Tag = asTag
  return (
    <Tag style={style} className={`${className} ${classes.t}`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Tag>
  )
}

T0.defaultProps = {
  asTag: 'span',
  className: '',
}

export default T0
