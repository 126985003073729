import injectSheet from 'react-jss'
import style from './style'

import BackgroundImage from '@/components/__ui/media/BackgroundImage'

const FullImage = ({ image, classes }) => {
  return (
    <div className={classes.FullImage}>
      <BackgroundImage src={image.sizes.large} alt={image.description} />
    </div>
  )
}

export default injectSheet(style)(FullImage)
