import { useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import * as loadingActions from '@/store/actions/loading'
import style from './style'

const useStyles = createUseStyles(style)

const Loader = () => {
  const classes = useStyles()
  const $root = useRef()
  // const $logo = useRef()
  const $spinner = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isSiteLoaded, isLoaderExited } = useSelector((state) => ({
    isSiteLoaded: state.loading.isSiteLoaded,
    isLoaderExited: state.loading.isLoaderExited,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setLoaderExited = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isLoaderExited', bool)), [dispatch])

  const onCompleteLoaderEnd = useCallback(() => setLoaderExited(true), [])
  /*------------------------------
  Loader End Animation
  ------------------------------*/
  const animateLoaderEnd = () => {
    const timeline = gsap.timeline({ onComplete: onCompleteLoaderEnd })
    timeline
      .to([$spinner.current], ({
        autoAlpha: 0,
      }), 1.2)
      .to($root.current, ({
        autoAlpha: 0,
      }), 2)
  }

  useEffect(() => {
    if (isSiteLoaded) animateLoaderEnd()
  }, [isSiteLoaded])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: isLoaderExited,
      })}
      ref={$root}
    >
      {/* <div className={classes.logoContainer}>
        <svg
          className={classes.logo}
          ref={$logo}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          width="127"
          height="118"
        >
          <circle cx={50} cy={50} r={50} />
        </svg>
      </div> */}
      <div className={classes.spinnerContainer}>
        <svg
          ref={$spinner}
          className={classes.spinner}
          viewBox="0 0 50 50"
          width="50"
          height="50"
        >
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="10" />
        </svg>
      </div>
    </div>
  )
}

export default Loader
