// import { useContext } from 'react'
import { useContext } from 'react'
import injectSheet, { useTheme } from 'react-jss'
import { Canvas } from '@react-three/fiber'
import { useContextBridge } from '@react-three/drei'
import { useContextSelector } from 'use-context-selector'
import { Context as ScrollCtx } from '@/context/scrollbar'
import { Context as MouseCtx } from '@/context/mouse'
import Scene from './Scene'
import style from './style'

const Cubes = ({ classes }) => {
  const theme = useTheme()
  const ScrollContextBridge = useContextBridge(MouseCtx)
  const sctx = useContextSelector(ScrollCtx, (ctx) => ctx)
  const mctx = useContext(MouseCtx)

  return (
    <div className={classes.cubesWrapper}>
      <ScrollContextBridge>
        <Canvas>
          <Scene theme={theme} sctx={sctx} mctx={mctx} />
        </Canvas>
      </ScrollContextBridge>
    </div>
  )
}

export default injectSheet(style)(Cubes)
