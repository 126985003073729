import { useState, useCallback } from 'react'
import { useTrail } from 'react-spring'
import AnimatedTextSplitter from './AnimatedTextSplitter'

const RevealText = ({
  children,
  className,
  inview,
  animateWords,
  from,
  to,
  config,
  reset,
  reverse,
  delay,
  loop,
  pause,
  onStart,
  onChange,
  onRest,
  onPause,
  onDelayEnd,
  onProps,
}) => {
  const [items, setItems] = useState([])
  const onRefChange = useCallback((node) => {
    const nodes = animateWords ? node?.querySelectorAll('span.word') : node?.querySelectorAll('span.char')
    setItems(nodes)
  }, [])

  //   useEffect(() => {
  //     console.log('items', items)
  //   }, [items])

  const trail = useTrail(items.length, {
    from: from(inview),
    to: to(inview),
    config,
    reset,
    reverse,
    delay,
    loop,
    pause,
    onStart,
    onChange,
    onRest,
    onPause,
    onDelayEnd,
    onProps,
  })

  return (
    <AnimatedTextSplitter
      className={`reveal-text ${className}`}
      ref={onRefChange}
      animatedTrail={trail}
      animateWords={animateWords}
    >
      {children}
    </AnimatedTextSplitter>
  )
}

RevealText.defaultProps = {
  inview: true,
  animateWords: false,
  from: (inview) => ({
    y: inview ? 0 : 50,
  }),
  to: (inview) => ({
    opacity: inview ? 1 : 0,
    y: inview ? 0 : 50,
  }),
  config: {
    mass: 1,
    tension: 5000,
    friction: 120,
  },
  reset: false,
  reverse: false,
  delay: 0,
  loop: false,
  pause: false,
  onStart: null,
  onChange: null,
  onRest: null,
  onPause: null,
  onDelayEnd: null,
  onProps: null,
}

export default RevealText
