import { useState, useRef } from 'react'
import injectSheet, { useTheme } from 'react-jss'
import { useContextSelector } from 'use-context-selector'

import { useTrail, useSpring, a } from 'react-spring'
import { Context as ScrollBarContext } from '@/context/scrollbar'
import { useRaf } from '@/components/__base/Handlers'

import style from './style'
import { T0 } from '@/components/_commons/Text'

const ScrollDown = ({ classes }) => {
  const theme = useTheme()
  const $scrollDown = useRef(null)
  const [visible, setVisible] = useState(true)

  const dots = [{ color: theme.colors[1] }, { color: theme.colors[1] }, { color: theme.colors[1] }]
  const trail = useTrail(dots.length, {
    from: { opacity: 1 },
    opacity: 0,
    config: { mass: 2, tension: 30, friction: 10 },
    loop: true,
  })

  const astyle = useSpring({
    opacity: visible ? 1 : 0,
    y: visible ? 0 : -10,
  })

  const scrollctx = useContextSelector(ScrollBarContext, (ctx) => ctx)

  useRaf(() => {
    const { scroll } = scrollctx
    if (scroll.current.y > window.innerHeight * 0.35) {
      setVisible(false)
    } else setVisible(true)
  }, [scrollctx])

  const handleClick = () => {
    scrollctx.scrollbar.scrollTo($scrollDown.current)
  }

  return (
    <a.div className={classes.ScrollDown} style={astyle} ref={$scrollDown} onClick={() => handleClick()}>
      <div className={classes.Dots}>
        {trail.map((dstyle, i) => {
          return (
            <a.span key={dstyle.opacity.id} style={{ ...dstyle, backgroundColor: dots[i].color }} />
          )
        })}
      </div>
      <T0>SCROLL TO DISCOVER</T0>
    </a.div>
  )
}

export default injectSheet(style)(ScrollDown)
