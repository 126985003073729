import { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import style from './style'

const useStyles = createUseStyles(style)

const RoundedBox = forwardRef((props, ref) => {
  const { children, round, background, className } = props
  const classes = useStyles({ round, background })

  return (
    <div {...props} ref={ref} className={`roundedBox ${classes.root} ${className}`}>
      {children}
    </div>
  )
})

RoundedBox.defaultProps = {
  round: '15px',
  background: 'transparent',
}

export default RoundedBox
