/* eslint-disable no-nested-ternary */
import { useSpring, a } from 'react-spring'

const RevealFadeUp = ({ inview, children, delay, config, className, style }) => {
  const astyles = useSpring({
    opacity: inview ? 1 : 0,
    y: inview ? 0 : 37,
    delay: delay * 1000,
    config,
  })

  return (
    <a.span
      style={{
        display: 'inline-flex',
        justifyContent: 'center',
        width: 'inherit',
        height: 'inherit',
        willChange: 'transform',
        ...style,
        ...astyles,
      }}
      className={`reveal-fade-up ${className}`}
    >
      {children}
    </a.span>
  )
}
RevealFadeUp.defaultProps = {
  config: {
    mass: 1.5,
    friction: 17,
    tension: 120,
  },
  delay: 0,
  className: '',
  style: {},
}

export default RevealFadeUp
