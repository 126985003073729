import { Suspense, useEffect, useCallback } from 'react'
import { ThemeProvider } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import GeneralContext from '@/context'
import ScrollbarContext from '@/context/scrollbar'
import Handlers from '@/components/__base/Handlers'
import LoadingContext from '@/context/loading'
import Routes from '@/router'
import Layout from '@/components/Layout'
import * as routingActions from '@/store/actions/routing'
import MouseContext from '@/context/mouse'

import * as optionsActions from '@/store/actions/options'
import * as localeActions from '@/store/actions/locale'
import useViewport from '@/hooks/useViewport'
import { theme } from '@/styles/style'

const App = () => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { routes, currentLanguage } = useSelector((state) => ({
    routes: state.routing.routes,
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  const { vw, vh, vwfixed, vhfixed } = useViewport()

  useEffect(() => {
    document.documentElement.style.setProperty('--vw', `${vw}px`)
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
    document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)
  }, [vw, vh, vwfixed, vhfixed])

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const setLanguage = useCallback(() => dispatch(localeActions.setLanguage()), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])

  useEffect(() => {
    setLanguage()
  }, [])

  useEffect(() => {
    if (currentLanguage !== null) {
      fetchRouting()
      fetchOptions()
    }
  }, [currentLanguage])

  return routes?.length > 0 && (
    <ThemeProvider theme={theme}>
      <Handlers>
        <GeneralContext>
          <LoadingContext>
            <ScrollbarContext>
              <MouseContext>
                <Layout>
                  <Suspense fallback={<div />}>
                    <Routes routes={routes} />
                  </Suspense>
                </Layout>
              </MouseContext>
            </ScrollbarContext>
          </LoadingContext>
        </GeneralContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
