import { mainCacheAxios as axios } from '@/http/axios'
import { REQUEST_ROUTING, RECEIVE_ROUTING } from '@/store/actions/types'
import { API } from '@/constants'

const requestRouting = () => ({ type: REQUEST_ROUTING })

const receiveRouting = (data) => ({
  type: RECEIVE_ROUTING,
  payload: data,
})

const fetchRouting = () => async (dispatch, getState) => {
  dispatch(requestRouting())
  if (window.routing) {
    dispatch(receiveRouting(window.routing))
  } else {
    const response = await axios.get(`${API.ROUTING}?lang=${getState().locale.currentLanguage}`)
    dispatch(receiveRouting(response.data))
  }
}

export {
  fetchRouting,
  receiveRouting,
}
