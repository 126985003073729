import { createUseStyles } from 'react-jss'
import useResponsive from '@/hooks/useResponsive'

const s = (theme) => ({
  t: ({ lg }) => ({
    fontSize: lg ? theme.fontSizes.mobile[2] : theme.fontSizes[2],
    fontWeight: 300,
    margin: '0px',
    '& *': {
      margin: 0,
    },
  }),
})
const useStyle = createUseStyles(s)

const T2 = ({ children, asTag, className, style, dangerouslySetInnerHTML }) => {
  const [,, lg] = useResponsive('lg')
  const classes = useStyle({ lg })
  const Tag = asTag

  return (
    <Tag style={style} className={`${className} ${classes.t}`} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Tag>
  )
}

T2.defaultProps = {
  asTag: 'p',
  className: '',
}

export default T2
