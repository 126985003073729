import {
  RECEIVE_PAGE,
  RECEIVE_POSTS,
  RECEIVE_POST,
  RECEIVE_CPTS,
  RECEIVE_CPT,
  RECEIVE_TAXONOMY,
} from '@/store/actions/types'

const initialState = {
  pages: {},
  posts: [],
  currentPageName: '',
  cpt: {},
  taxonomies: {},
  totalPosts: 0,
  totalPages: 1,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PAGE:
      state = {
        ...state,
        pages: {
          ...state.pages,
          [action.key]: {
            ...action.payload,
            child: action.child || [],
          },
        },
        currentPageName: action.key,
      }
      break
    case RECEIVE_CPT:
      state = {
        ...state,
        cpt: {
          [action.key]: [
            ...(state.cpt[action.key] || []),
            ...action.payload,
          ],
        },
      }
      break
    case RECEIVE_CPTS:
      state = {
        ...state,
        cpt: {
          ...state.cpt,
          [action.key]: action.payload,
        },
      }
      break
    case RECEIVE_POSTS:
      state = {
        ...state,
        posts: action.payload,
        totalPosts: action.totalPosts,
        totalPages: action.totalPages,
      }
      break
    case RECEIVE_TAXONOMY:
      state = {
        ...state,
        taxonomies: {
          ...state.taxonomies,
          [action.key]: action.payload,
        },
      }
      break
    case RECEIVE_POST:
      state = {
        ...state,
        posts: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
