import { memo } from 'react'
import styled from 'styled-components'
import { a } from 'react-spring'

const Aspan = styled(a.span)`
    display: ${({ children }) => (children === ' ' ? 'inline' : 'inline-block')};
`

const Char = ({ children, astyle }) => {
  const className = children === ' ' ? 'white-space' : 'char'
  return <Aspan style={astyle} className={className}>{children}</Aspan>
}

export default memo(Char)
