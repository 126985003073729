import InViewObserver from '@/components/__ui/reveals/InViewObserver'

const withInViewTrigger = (Component) => {
  return (props) => {
    return (
      <InViewObserver>
        <Component {...props} />
      </InViewObserver>
    )
  }
}

export default withInViewTrigger
