import { useRef, useMemo, useEffect, useCallback, useState } from 'react'

import { useFrame, useThree } from '@react-three/fiber'
import { useGLTF } from '@react-three/drei'
import { MeshStandardMaterial, LinearEncoding } from 'three'
import { useSpring, a } from '@react-spring/three'

const Scene = ({ theme, sctx, mctx }) => {
  const three = useThree()
  // console.log('three', three)
  const { viewport: { width }, size, gl, camera } = three
  gl.outputEncoding = LinearEncoding
  // gl.toneMapping = THREE.NoToneMapping
  const { nodes: nodesBoxes } = useGLTF('https://dplus.ventures/boxes/boxes.glb')
  const { nodes: nodesBox } = useGLTF('https://dplus.ventures/boxes/box.glb')

  const [readyToAnimate, setReadyToAnimate] = useState(false)

  const $mesh = useRef()
  const $mesh1 = useRef()
  const $group = useRef()

  useEffect(() => {
    camera.position.set(0, 0, 19)
    camera.fov = 40
    camera.updateProjectionMatrix()
  }, [])

  const material = useMemo(() => (
    new MeshStandardMaterial({
      color: theme.colors[3],
      roughness: 0.5,
      metalness: 0.8,
    })
  ), [])
  const material1 = useMemo(() => (
    new MeshStandardMaterial({
      color: theme.colors[4],
      roughness: 0.6,
      metalness: 0.8,
    })
  ), [])

  const [{ rotation, position1, position2 }, api] = useSpring(() => ({
    rotation: [0, 0, 0],
    position1: [0, 0, 0],
    position2: [0, 0, 0],
    config: {
      mass: 4,
      tension: 100,
      friction: 100,
    },
  }))

  const getScroll = useCallback(() => {
    return sctx?.scroll?.current || { x: 0, y: 0 }
  }, [sctx])

  const getMouse = useCallback(() => {
    return mctx?.mouse?.current || { x: 0, y: 0 }
  }, [mctx])

  useFrame(() => {
    $group.current.position.y = getScroll().y * 0.01
    if (!readyToAnimate) return
    api.start({
      rotation: [
        getScroll().y * 0.001,
        getMouse().x / size.width,
        getMouse().y / size.height,
      ],

      position1: [
        20,
        getScroll().y * 0.001,
        20,
      ],
      position2: [
        20,
        getScroll().y * 0.0015,
        -20,
      ],
    })
  })

  useEffect(() => {
    setTimeout(() => {
      setReadyToAnimate(true)
    }, 1500)
  }, [])

  return (
    <>
      <a.ambientLight color="#ffffff" intensity={1.2} />
      <a.pointLight position={position1} color="#ffffff" intensity={0.8} />
      <a.pointLight position={position2} color="#ffffff" intensity={0.8} />

      <group
        dispose={null}
        ref={$group}
      >
        <a.mesh
          ref={$mesh}
          castShadow
          receiveShadow
          geometry={nodesBox.quadrato.geometry}
          material={material}
          position={[-width * 0.45, -4, -1]}
          rotation={rotation}

        />
        <a.mesh
          ref={$mesh1}
          castShadow
          receiveShadow
          geometry={nodesBoxes.quadrati.geometry}
          material={material1}
          position={[width * 0.80, -9, -3]}
          rotation={rotation}
        />
        <a.mesh
          ref={$mesh}
          castShadow
          receiveShadow
          geometry={nodesBox.quadrato.geometry}
          material={material1}
          position={[-width * 0.45, -19, -1]}
          rotation={rotation}

        />
        <a.mesh
          ref={$mesh1}
          castShadow
          receiveShadow
          geometry={nodesBoxes.quadrati.geometry}
          material={material1}
          position={[width * 0.77, -30, -1]}
          rotation={rotation}
        />
        <a.mesh
          ref={$mesh}
          castShadow
          receiveShadow
          geometry={nodesBox.quadrato.geometry}
          material={material}
          position={[width * 0.85, -46, -4]}
          rotation={rotation}

        />

      </group>

    </>

  )
}

export default Scene
