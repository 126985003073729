const style = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: `${theme.spaces[4]} auto`,
  },
  bioWrapper: {
    width: '46%',
    margin: `${theme.spaces[4]} auto`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& figure': {
      margin: '0px',
      width: '34%',
      display: 'flex',
      alignItems: 'start',
    },
    '& figcaption': {
      flexBasis: '50%',
      textAlign: 'center',
      '& h3': {
        marginTop: theme.spaces[2],
        marginBottom: theme.spaces[2],
      },
      '& a': {
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  profiles: {
    height: '70px',
    marginTop: theme.spaces[2],
    display: 'flex',
    justifyContent: 'center',
    '& .l': {
      height: '100%',
      display: 'flex',
      color: theme.colors[1],
      fontWeight: '300',
      alignItems: 'center',
      transition: 'all ease-out 0.4s',
      marginRight: theme.spaces[2],
      textDecoration: 'none',
      '&:last-child': { marginRight: '0px' },
      '& .ico': {
        display: 'inline-block',
        height: '70px',
        width: '70px',
        padding: '23px',
        borderRadius: '50%',
        border: `solid 1px ${theme.getRgba(theme.colors[1], 0.3)}`,
        transition: 'all ease-out 0.4s',
        '& svg': {
          height: '100%',
          width: '100%',
          fill: theme.colors[1],
        },
      },
      '& .label': {
        display: 'inline-block',
        marginLeft: theme.spaces[1],
        fontSize: theme.fontSizes[0],
        opacity: 0.7,
        letterSpacing: 0.5,
        textAlign: 'left',
      },
      '&:hover': {
        color: theme.colors[3],
        '& .ico': {
          border: `solid 1px ${theme.colors[3]}`,
        },
      },

    },
  },
  [theme.mediaInverse.lg]: {
    root: {
      margin: `${theme.spaces[4]} 0px ${theme.spaces[1]} 0px`,
    },
    bioWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: `${theme.spaces[2]} auto`,
      '& figure': {
        marginBottom: theme.spaces[0],
        padding: `0px ${theme.spaces[1]}`,
        width: '100%',
      },
      '& figcaption': {
        width: '100%',
      },
    },
    profiles: {
      height: 'auto',
      marginTop: theme.spaces[2],
      display: 'flex',
      flexDirection: 'column',
      '& .l': {
        height: '60px',
        marginBottom: theme.spaces[1],
        '& .ico': {
          height: '60px',
          width: '60px',
          padding: '20px',
        },
      },
    },
  },
})

export default style
