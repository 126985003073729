const style = () => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      width: '100%',
      height: 0,
      paddingBottom: '56.25%',
    },
    isFirstTime: {
      '& $poster': {
        pointerEvents: 'none',
        // opacity: 0,
      },
    },

    /*------------------------------
    Poster
    ------------------------------*/
    poster: {
      position: 'absolute',
      zIndex: 3,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      transition: 'opacity .3s ease-out',
      pointerEvents: 'none',
      '& img, & video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    posterPlay: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: '50%',
      width: 50,
      height: 50,
      padding: 10,
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      border: '2px solid rgb(255, 255, 255)',
      background: 'rgba(255, 255, 255, .1)',
      display: 'none',
      '& svg': {
        fill: 'rgb(255, 255, 255)',
        transform: 'translateX(3%)',
      },
    },

    /*------------------------------
    Video
    ------------------------------*/
    video: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      transition: 'opacity .3s ease-out',

      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    /*------------------------------
    Controllers
    ------------------------------*/
    controllers: {
      position: 'absolute',
      padding: [0, 3],
      zIndex: 2,
      bottom: 0,
      left: 0,
      width: 'calc(100% - 6px)',
      display: 'flex',
      alignItems: 'center',
      userSelect: 'none',
      paddingTop: 10,
      background:
        'linear-gradient(to top, rgba(0, 0, 0, .3), rgba(0, 0, 0, 0))',

      '& button': {
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        padding: 0,
        width: 32,
        height: 32,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& svg': {
          fill: 'rgb(255, 255, 255)',
          width: '100%',
          height: '100%',
        },
      },
    },

    /*------------------------------
    Progress
    ------------------------------*/
    progressWrap: {
      flex: '1 1 auto',
      height: 40,
      position: 'relative',
      zIndex: 1,
      cursor: 'grab',
      margin: [0, 6],
      '&:hover': {
        '& $progressBall': {
          opacity: 1,
        },
      },
    },
    isDragging: {
      cursor: 'grabbing!important',
    },
    progressPlaceholder: {
      position: 'absolute',
      zIndex: 1,
      top: '50%',
      left: 0,
      width: '100%',
      height: 1,
      background: 'rgba(255, 255, 255, .3)',
    },
    progress: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      left: 0,
      width: '100%',
      height: 1,
      background: 'rgba(255, 255, 255, 1)',
      transformOrigin: '0 0',
      transform: 'scaleX(0)',
    },
    progressBall: {
      position: 'absolute',
      zIndex: 1,
      top: 17,
      left: '100%',
      width: 7,
      height: 7,
      background: 'rgb(255, 255, 255)',
      borderRadius: '50%',
      opacity: 0,
      transition: 'opacity .2s ease-out',
    },

    /*------------------------------
    Volume
    ------------------------------*/
    volumeWrap: {
      position: 'relative',
      zIndex: 1,
      '&:hover': {
        '& $volumeRange': {
          opacity: 1,
        },
      },
    },
    volumeRange: {
      position: 'absolute',
      top: -3,
      left: 9,
      zIndex: 1,
      transformOrigin: '0 0',
      transform: 'rotate(-90deg)',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      opacity: 0,
      transition: 'opacity .2s ease-out',
      width: 100,
      height: 7,
      background: 'transparent',
      '&::-webkit-slider-runnable-track': {
        WebkitAppearance: 'none',
        background: 'rgb(255, 255, 255)',
        height: '1px',
      },
      '&::-moz-range-track': {
        WebkitAppearance: 'none',
        background: 'rgb(255, 255, 255)',
        height: '1px',
      },
      '&:focus': {
        outline: 'none',
      },
      '&::-webkit-slider-thumb': {
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        borderRadius: '50%',
        border: 'none',
        height: '11px',
        width: '11px',
        maxWidth: '80px',
        position: 'relative',
        bottom: '5px',
        backgroundColor: 'rgb(255, 255, 255)',
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
      },
      '&::-moz-range-thumb': {
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        border: '2px solid',
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        maxWidth: '80px',
        position: 'relative',
        bottom: '9px',
        backgroundColor: 'rgb(255, 255, 255)',
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
      },
    },

    playIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 999,
      pointerEvents: 'none',
      backgroundColor: 'rgba(0,0,0,0.9)',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      width: '66px',
      transform: 'translate(-50%, -50%)',
      '& svg': {
        fill: '#ffffff',
        height: '43px',
        display: 'block',
      },
    },

    posterOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
  }
  return output
}

export default style
