import { memo, useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import SmoothScroll from '@/components/__base/SmoothScroll'
import Meta from '@/components/__base/Meta'

import ProgressBar from '@/components/_pages/Home/ProgressBar'
import Hero from '@/components/_pages/Home/Hero'
import VideoQuotes from '@/components/_pages/Home/VideoQuotes'
import FullImage from '@/components/_pages/Home/FullImage'
import Bio from '@/components/_pages/Home/Bio'
import Footer from '@/components/_commons/Footer'
import Header from '@/components/_commons/Header'
import style from './style'

import usePage from '@/hooks/usePage'
import useResponsive from '@/hooks/useResponsive'

import Cubes from '@/components/_pages/Home/Cubes'

const useStyles = createUseStyles(style)

const HomePage = ({
  stub,
}) => {
  const classes = useStyles()

  const $root = useRef()
  const [page, load] = usePage(stub)

  const [hero, setHero] = useState()
  const [videoQuotes, setVideoQuotes] = useState()
  const [fullImage, setFullImage] = useState()
  const [bio, setBio] = useState()
  const [footer, setFooter] = useState()

  const [Lg, NotLg] = useResponsive('lg')
  const [, NotXL] = useResponsive('xl')

  useEffect(() => {
    console.log('page', page)
    if (!Object.keys(page).length) return
    setHero(page.acf.hero)
    setVideoQuotes(page.acf.video)
    setFullImage(page.acf.full_image)
    setBio(page.acf.bio)
    setFooter(page.acf.footer)
  }, [page])

  return (
    <>
      <NotXL>
        <Cubes />
      </NotXL>
      <SmoothScroll
        init={load}
        className={`page pageHome ${classes.root}`}
        ref={$root}
      >
        <Lg>
          <Header />
        </Lg>
        {
        load && (
          <Meta
            title={page.title.rendered}
            meta={page.yoast_meta}
            schema={page.yoast_json_ld}
          />
        )
      }
        {load && (
        <>
          {hero && <Hero item={hero} />}
          {videoQuotes && <VideoQuotes item={videoQuotes} />}
          {fullImage && <FullImage image={fullImage.image} />}
          {bio && <Bio item={bio} />}
          {footer && <Footer item={footer} />}
        </>
        )}
      </SmoothScroll>
      <NotLg>
        <ProgressBar />
      </NotLg>
    </>

  )
}

export default memo(HomePage)
