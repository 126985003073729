import { memo } from 'react'
import { createUseStyles } from 'react-jss'
import Loader from '@/components/__ui/indicators/Loader'
import Header from '@/components/_commons/Header'
// import Spinner from '@/components/__ui/indicators/Spinner'
import SVGSprite from '@/components/__ui/icons/SVGSprite'
import useFontPreload from '@/hooks/useFontPreload'
import useScrollTriggerProxy from '@/hooks/useScrollTriggerProxy'
import useChangePathname from '@/hooks/useChangePathname'
import global from '@/styles/global'
import useResponsive from '@/hooks/useResponsive'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()

  /*------------------------------
  Precache fonts
  ------------------------------*/
  useFontPreload({ fonts: ['AktivGrotesk'] })

  /*------------------------------
  Init Scroll Trigger Proxy
  ------------------------------*/
  useScrollTriggerProxy()

  /*------------------------------
  Manage Change Pathname
  ------------------------------*/
  useChangePathname()

  const [, NotLg] = useResponsive('lg')

  return (
    <div className={`${classes.root} content-root`}>
      <div className="wrapper">
        {children}
      </div>
      <SVGSprite />
      {/* <Spinner /> */}
      <NotLg>
        <Header />
      </NotLg>
      {/* <Overlay /> */}
      {/* <ModalDownload /> */}
      {/* <CursorFollow /> */}
      <Loader />
    </div>
  )
}

export default memo(Layout)
