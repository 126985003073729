import hexToRgba from 'hex-rgba'

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 992,
  xl: 1025,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

const colors = [
  '#272E31', // #0 Dark
  '#ffffff', // #1 White
  '#000000', // #2 Black
  '#fdae4b', // #3 Indian Yellow
  '#5A8291', // #4 Teal
]

const fonts = [
  '"AktivGrotesk", Helvetica, Arial, sans-serif', // [0]
]

const fontSizes = {
  0: '12px',
  1: '18px',
  2: '20px',
  3: '42px',
  4: '60px',
  mobile: {
    0: '12px',
    1: '18px',
    2: '20px',
    3: '24px',
    4: '36px',
  },
}

const spaces = {
  0: '10px',
  1: '20px',
  2: '40px',
  3: '60px',
  4: '120px',
}

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
  garret: 'cubic-bezier(0.5, 0, 0, 1);', // [3]
}

const header = {
  height: '63px',
  mobile: { height: '59px' },
}

const zindex = {
  overlay: 101,
  mainNav: 102,
  header: 103,
  cursor: 102,
  modal: 104,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

export const theme = {
  media,
  mediaInverse,
  colors,
  zindex,
  easings,
  fonts,
  fontSizes,
  sizes,
  spaces,
  header,
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
}
