import { useContext, useRef, forwardRef } from 'react'

// import Modernizr from 'modernizr'

import gsap from 'gsap'
import mergeRefs from 'react-merge-refs'
import { useRaf } from '@/components/__base/Handlers'
import { Context as MouseCtx } from '@/context/mouse'
import { distance, lerp } from '@/utils/math'

const wrapperStyle = {
  display: 'inline-block',
}

const animationWrapperStyle = {
  display: 'inline-block',
  willChange: 'tranform',
}

const AttractMe = forwardRef(({
  children,
  friction,
  attractionRadius,
  translateCenter,
  className,
  direction,
}, ref) => {
  const $awRef = useRef(null)
  const $wRef = useRef(null)

  const { mouse: { current: mouse } } = useContext(MouseCtx)

  const translate = useRef({ x: 0, y: 0 })

  useRaf(() => {
    if (!$wRef.current) return
    const bounds = $wRef.current.getBoundingClientRect()
    const { x: mX, y: mY } = mouse
    const { x, y, width, height } = bounds

    const mv = { x: mX, y: mY }
    const elV = { x: (x + width / 2) + translateCenter.x, y: (y + height / 2) + translateCenter.y }

    const dist = distance(mv, elV)

    if (dist < attractionRadius) {
      const tx = (mv.x - elV.x) * (1 - friction) * direction
      const ty = (mv.y - elV.y) * (1 - friction) * direction
      translate.current.x = lerp(translate.current.x, tx, 0.03)
      translate.current.y = lerp(translate.current.y, ty, 0.03)
    } else {
      translate.current.x = lerp(translate.current.x, 0, 0.06)
      translate.current.y = lerp(translate.current.y, 0, 0.06)
    }

    gsap.set($awRef.current, {
      overwrite: true,
      x: translate.current.x,
      y: translate.current.y,
    })
  }, [$awRef.current, $wRef.current, translateCenter])

  return (
    <div style={wrapperStyle} className={`attractorWrapper ${className}`} ref={mergeRefs([ref, $wRef])}>
      <div style={animationWrapperStyle} className="animationWrapper" ref={$awRef}>
        {children}
      </div>
    </div>
  )
})

AttractMe.defaultProps = {
  friction: 0.9,
  attractionRadius: 300,
  translateCenter: { x: 0, y: 0 },
  direction: 1,
  className: '',
}

export default AttractMe
