/* eslint-disable jsx-a11y/img-redundant-alt */
import injectSheet from 'react-jss'
import ReactHtmlParser from 'react-html-parser'
import { useSelector, shallowEqual } from 'react-redux'
import style from './style'

import { Container } from '@/components/_commons/grid'
import { T4, T1 } from '@/components/_commons/Text'
import RoundedBox from '@/components/_commons/RoundedBox'
import DelayLink from '@/components/__ui/navigation/DelayLink'
import InViewObserver from '@/components/__ui/reveals/InViewObserver'
import RevealText from '@/components/__ui/reveals/RevealText'
import RevealFadeUp from '@/components/__ui/reveals/RevealFadeUp'
import withInViewTrigger from '@/hoc/withInViewTrigger'

const Bio = ({ item, classes, inview }) => {
  const { strings } = useSelector((state) => ({
    strings: state.options.strings,
  }), shallowEqual)

  return (
    <Container className={classes.root}>
      <div className={classes.bioWrapper}>
        <figure>
          <InViewObserver>
            <RevealFadeUp>
              <RoundedBox round="50%">
                <img src={item.image?.sizes.large} alt={item.image?.description} />
              </RoundedBox>
            </RevealFadeUp>
          </InViewObserver>
        </figure>
        <figcaption>
          <T4 asTag="h3">
            <RevealText
              inview={inview}
              from={() => ({
                y: 5,
                scale: 0.5,
                opacity: 0,
              })}
              to={(inView) => ({
                scale: inView ? 1 : 0.5,
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 5,
              })}
              config={{
                mass: 1,
                tension: 3000,
                friction: 120,
              }}
            >
              {item.name}
            </RevealText>
          </T4>
          <T1>
            {ReactHtmlParser(item.bio)}
          </T1>
          <div className={classes.profiles}>
            <DelayLink className="l" to={strings.linkedin_url}>
              <span className="ico">
                <svg><use xlinkHref="#ico-linkedin1" /></svg>
              </span>
              <span className="label">
                LINKEDIN
              </span>
            </DelayLink>
            <DelayLink className="l" to={strings.goodreads_url}>
              <span className="ico">
                <svg><use xlinkHref="#ico-goodreads" /></svg>
              </span>
              <span className="label">
                GOODREADS
              </span>
            </DelayLink>
          </div>
        </figcaption>
      </div>
    </Container>
  )
}

export default injectSheet(style)(withInViewTrigger(Bio))
