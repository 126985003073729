import { createUseStyles } from 'react-jss'
import style from './style'
import DelayLink from '@/components/__ui/navigation/DelayLink'

const useStyle = createUseStyles(style)

const SquareButton = ({ to, children, icon, className = '' }) => {
  const classes = useStyle()
  return (
    <DelayLink to={to} className={`${classes.SquareButton} ${className}`}>
      {children}
      {icon && <svg><use xlinkHref={`#ico-${icon}`} /></svg>}
    </DelayLink>
  )
}

export default SquareButton
