import { createUseStyles } from 'react-jss'
import { a } from 'react-spring'
import style from './style'

const useStyles = createUseStyles(style)
const BackgroundImage = (props) => {
  const { src, url, alt, paddingTop, style: s, className } = props
  const classes = useStyles()

  return (
    <a.div
      className={`bgImage ${classes.root} ${className}`}
      style={{ ...s }}
    >
      <div style={{ backgroundImage: `url(${src || url})`, paddingTop }}>
        <img
          src={src || url}
          alt={alt}
          style={{ opacity: 0, display: paddingTop ? 'none' : 'block',
          }}
        />
      </div>
    </a.div>
  )
}

BackgroundImage.defaultProps = {
  style: {},
}

export default BackgroundImage
