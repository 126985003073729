import bounds from '@/store/reducers/bounds'
import catalog from '@/store/reducers/catalog'
import content from '@/store/reducers/content'
import cursor from '@/store/reducers/cursor'
import form from '@/store/reducers/form'
import layer from '@/store/reducers/layer'
import layout from '@/store/reducers/layout'
import loading from '@/store/reducers/loading'
import locale from '@/store/reducers/locale'
import nav from '@/store/reducers/nav'
import options from '@/store/reducers/options'
import routing from '@/store/reducers/routing'
import modal from '@/store/reducers/modal'

export default {
  bounds,
  catalog,
  content,
  cursor,
  form,
  layer,
  layout,
  loading,
  locale,
  nav,
  options,
  routing,
  modal,
}
