import { useEffect, useContext, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Context as LoadingContext } from '@/context/loading'
import { precacheMedia } from '@/utils/media'
import { removeUndefined } from '@/utils/array'
import * as loadingActions from '@/store/actions/loading'

const usePagePrecache = ({
  init = false,
  sources = [],
  callback = () => {},
}) => {
  const { setReady, isLoaderExited } = useContext(LoadingContext)
  const [trigger, setTrigger] = useState(false)
  const [load, setLoad] = useState(false)
  const [animationTrigger, setAnimationTrigger] = useState(false)

  const dispatch = useDispatch()
  const setSiteLoaded = useCallback((bool) => dispatch(loadingActions.setLoadingValue('isSiteLoaded', bool)), [dispatch])

  /*------------------------------
  Complete preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setTrigger(true)
  }

  /*------------------------------
  Start the media precache
  ------------------------------*/
  useEffect(() => {
    if (init && removeUndefined(sources).length > 0) precacheMedia(removeUndefined(sources), completePreload)
    if (init && removeUndefined(sources).length === 0) completePreload()
  }, [init])

  /*------------------------------
  Check if Fonts & Audio are ready
  ------------------------------*/
  useEffect(() => {
    if (trigger) {
      setLoad(true)
    }
  }, [trigger])

  /*------------------------------
  Check if load is true and loader is exited
  ------------------------------*/
  useEffect(() => {
    if (load && isLoaderExited) {
      setAnimationTrigger(true)
      callback()
    }
  }, [load, isLoaderExited])

  /*------------------------------
  Return values
  ------------------------------*/
  return [load, animationTrigger]
}

export default usePagePrecache
