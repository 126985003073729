import { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import style from './style'

const useStyles = createUseStyles(style)
const Container = forwardRef((props, ref) => {
  const classes = useStyles()
  return (
    <div className={`${classes.Container} ${props.className}`} ref={ref}>
      {props.children}
    </div>
  )
})

Container.defaultProps = {
  className: '',
}

export default Container
