/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useMemo, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useRaf } from '@/components/__base/Handlers'
import {
  icoPlay,
  icoPause,
  icoFullscreen,
  icoVolumeOn,
  icoVolumeOff,
} from './icons'
import style from './style'

const useStyles = createUseStyles(style)

const VideoPlayer = ({
  className,
  poster,
  posterVideo,
  title,
  video,
  autoplay,
  loop,
  controllers,
}) => {
  const classes = useStyles(style)
  const $video = useRef()
  const $progressWrap = useRef()
  const $progressBall = useRef()
  const $progress = useRef()
  const $volume = useRef()
  const $videoPoster = useRef(null)
  const args = useMemo(() => {
    return {
      autoPlay: true,
      muted: autoplay,
      playsInline: autoplay,
      loop,
      controls: false,
    }
  }, [autoplay, loop])

  /*------------------------------
  States
  ------------------------------*/
  const [play, setPlay] = useState(autoplay)
  const [firstTime, setFirstTime] = useState(false)
  const [muted, setMuted] = useState(autoplay)
  const [progressDown, setProgressDown] = useState(false)
  const [volume, setVolume] = useState(0.5)

  useEffect(() => {
    if (play) {
      if (!firstTime) {
        setFirstTime(true)
      }
      $video.current.play()
    } else {
      $video.current.pause()
    }
  }, [play, firstTime])

  useEffect(() => {
    if (muted) {
      $video.current.volume = 0
      if (controllers) {
        $volume.current.value = 0
      }
    } else {
      $video.current.volume = volume
      if (controllers) {
        $volume.current.value = volume
      }
    }
  }, [muted, controllers, volume])

  /*------------------------------
  Poster
  ------------------------------*/
  const renderPoster = () => {
    return (
      <>
        {
          poster && (
            <div className={classes.poster} onClick={() => setPlay(!play)}>
              <div className={classes.posterPlay}>{icoPlay()}</div>
              <img src={poster} alt={title} />
            </div>
          )
        }
        {
          posterVideo && (
            <div
              className={classes.poster}
            >
              <div className={classes.posterOverlay} />
              <video autoPlay={true} loop muted ref={$videoPoster}>
                <source src={posterVideo} />
              </video>
            </div>

          )
        }
      </>

    )
  }

  /*------------------------------
  Video
  ------------------------------*/
  const renderVideo = () => {
    return (
      <div className={classes.video}>
        <video
          ref={$video}
          title={title}
          {...args}
          onEnded={() => {
            if (!loop) {
              if (!$video.current) return
              $video.current.currentTime = 0
              setPlay(false)
            }
          }}
          onClick={() => {
            setPlay(!play)
          }}
        >
          <source src={video} />
        </video>
      </div>
    )
  }

  /*------------------------------
  Progress Raf
  ------------------------------*/
  useRaf(() => {
    if (controllers) {
      const { currentTime, duration } = $video.current
      $progress.current.style.transform = `scaleX(${currentTime / duration})`
      $progressBall.current.style.left = `${(currentTime / duration) * 100}%`
    }
  })

  /*------------------------------
  Handle Progress
  ------------------------------*/
  const handleProgress = (e) => {
    if (progressDown) {
      const { duration } = $video.current
      const { left, width } = $progressWrap.current.getBoundingClientRect()
      const x = (e.pageX - left) / width
      $video.current.currentTime = x * duration
    }
  }

  /*------------------------------
  Handle Fullscreen
  ------------------------------*/
  const handeFullscreen = () => {
    if ($video.current.requestFullscreen) {
      $video.current.requestFullscreen()
    }
  }

  /*------------------------------
  Controllers
  ------------------------------*/
  const renderControllers = () => {
    return (
      controllers && (
        <div
          className={classNames({
            [classes.controllers]: true,
            [classes.isDragging]: progressDown,
          })}
        >
          <button className={classes.play} onClick={() => setPlay(!play)}>
            {play ? icoPause() : icoPlay()}
          </button>

          <div
            ref={$progressWrap}
            className={classes.progressWrap}
            onMouseDown={(e) => {
              setProgressDown(true)
              handleProgress(e)
            }}
            onPointerDown={() => setProgressDown(true)}
            onPointerLeave={() => setProgressDown(false)}
            onPointerUp={() => setProgressDown(false)}
            onPointerMove={handleProgress}
          >
            <div className={classes.progressPlaceholder} />
            <div ref={$progress} className={classes.progress} />
            <div ref={$progressBall} className={classes.progressBall} />
          </div>
          <div className={classes.volumeWrap}>
            <button className={classes.volume} onClick={() => setMuted(!muted)}>
              {muted ? icoVolumeOff() : icoVolumeOn()}
            </button>
            <input
              className={classes.volumeRange}
              ref={$volume}
              type="range"
              min="0"
              max="1"
              step=".1"
              defaultValue="1"
              onChange={(e) => {
                // setMuted($volume.current.value === 0)
                // $video.current.volume = $volume.current.value
                setVolume(e.target.value)
              }}
            />
          </div>
          <button className={classes.fullscreen} onClick={handeFullscreen}>
            {icoFullscreen()}
          </button>
        </div>
      )
    )
  }

  const renderPlayIcon = () => {
    return (
      <div className={classes.playIcon}>
        {icoPlay()}
      </div>
    )
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: true,
        videoPlayer: true,
        [classes.isPlaying]: play,
        [classes.isFirstTime]: firstTime,
      })}
    >
      { !play && renderPoster()}
      { !play && renderPlayIcon()}
      {renderVideo()}
      {renderControllers()}
    </div>
  )
}

export default VideoPlayer
