import { useRef } from 'react'
import { useContextSelector } from 'use-context-selector'
import { createUseStyles } from 'react-jss'
import style from './style'
import { Container } from '@/components/_commons/grid'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf } from '@/components/__base/Handlers'

const useStyles = createUseStyles(style)
const ProgressBar = () => {
  const classes = useStyles()
  const { limit, scroll } = useContextSelector(ScrollbarContext, (ctx) => ctx)
  const $thumb = useRef(null)

  useRaf(() => {
    if (!$thumb.current) return
    const { current: { y: ly } } = limit
    const { current: { y: sy } } = scroll
    $thumb.current.style.height = `${(sy / ly) * 100}%`
  }, [limit, scroll, $thumb])
  return (
    <div>
      <Container className={classes.progressBarWrapper}>
        <div className={classes.track}>
          <div className={classes.thumb} ref={$thumb} />
        </div>
      </Container>
    </div>

  )
}

export default ProgressBar
